import React, { ChangeEvent, MouseEvent, KeyboardEvent } from 'react';

import { LoginConf } from 'ekip-webcomponents'

import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';

import './Assets/Toast.css';

import { headers, url } from './Components/Security';

import { MyState } from './Components/Interface';

import { MyProps } from './Components/Props';

import background from './Assets/background.jpg'


class Conference extends React.Component<any, MyState>{

    constructor(props: any) {
        super(props);
        this.state = MyProps
    }

    public componentDidMount(): void {

        axios.get(url + "/ --version ", headers).then(response => {
    
            if (response.status == 200) {
    
                this.setState({ version: response.data[200]['content'] });
            }
        });
        
        const queryParams = new URLSearchParams(window.location.search);

        const hash = queryParams.get('hash');

        if (hash != undefined && typeof hash == 'string'){

            this.setState({hash: hash});

            this.setState({noConference: false});

        } else {

            this.setState({showHash: true});

            this.webconfs();
        }
    }

  
    public webconfs = () : void => {
        
        axios.get(url + "/webconfs/", headers).then(response => {

            if (response.status == 200 && 200 in response.data) {

                let values:any = [];

                Object.entries(response.data[200]['content']).forEach(([key, value], i) => {

                    values.push({value:key, label:value});
                });

                this.setState({values: values});
                  
                this.setState({noConference: false});

            } else {

                this.setState({noConference: true});
            }
        });
    }


    public join = (name:string, password:string) : void => {
        
        let data:any = {
            'hash': this.state.hash ,
            'username': name,
            'password': password
        };

        axios.post(url + "/join/", data, headers).then(response => {
            
            if (response.status == 200 && 200 in response.data) {

                window.location.href = response.data[200]['content'];

            } else {

                toast.error(response.data[404]['description'], this.state.configToast);
            }
        });
    }


    public render() {

        return (

                <>

                <ToastContainer />
                
                <LoginConf 
                
                    urlBackground = { background }

                    fieldAuto = {{
                        placeholder: 'Selectionnez une salle de conférence',
                        fieldLabel: 'Salle de conférence',
                        name: 'room',
                        options: this.state.values,
                        isDisabled: this.state.noConference,
                        selectChange: () => {},
                    }}

                    showSelect = { this.state.showHash }

                    elementLoginFields = {[
                        
                        {
                            elementClass: 'formGroup_field',
                            islabel: true,
                            fieldLabel: 'Votre nom / alias',
                            fieldType: 'text',
                            fieldName: 'name',
                            fieldTemplate: 'default',
                            isRequired: true
                        },
                        {
                            elementClass: 'formGroup_field',
                            islabel: true,
                            fieldLabel: 'Mot de passe de la conférence',
                            fieldType: 'password',
                            fieldName: 'password',
                            fieldTemplate: 'default',
                            isRequired: true
                        },
                        {
                            elementClass: 'formGroup_field formGroup_field_containerSubmit_submit',
                            islabel: false,
                            fieldValue: 'Se connecter',
                            fieldType: 'submit',
                            fieldTemplate: 'default',
                        }
                    ]}
                    
                    onSubmitForm = { (e:any) => {

                        e.preventDefault();

                        let data:any = {};

                        [...e.currentTarget.elements]
                            .filter((ele) => ele.type !== "submit" && ele.type !== "checkbox")
                            .map((ele) => {
                                data[ele.getAttribute("name")] = ele.value;
                        });

                        if (this.state.noConference){

                            toast.warning("Aucune salle de conférence n'est disponible.", this.state.configToast);

                        } else if (this.state.showHash){

                            if (data['room'] != undefined && data['room'] != null && data['room'] != ""){
                            
                                this.setState({hash: data['room']}, () => {

                                    this.join(data['name'], data['password']);
                                });

                            } else {

                                toast.warning("Aucune salle de conférence n'a été selectionnée.", this.state.configToast);
                            }

                        } else if (this.state.hash != undefined && this.state.hash != null && this.state.hash != ''){

                            this.join(data['name'], data['password']);

                        } else {

                            toast.warning("Aucune salle de conférence n'a été selectionnée.", this.state.configToast);
                        }
                    }}

                    version = {{ version: this.state.version, urlVersion: '/#' }}

                />
                
            </>
        );
    }

}

export default Conference;
