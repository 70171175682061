export let MyProps = { 
    id: 0,
    hash: '',
    name: '',
    password: '',
    values: [],
    showHash: false,
    noConference: true,
    configToast : {
        position : 'bottom-right',
        autoClose: 5000,
        closeButton : false,
        hideProgressBar : true,
        closeOnClick: false,
    },
    version:'0.0.0',
}