import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Conference from './Conference';


export default function App() {

  return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Conference/>} />
            </Routes>
        </BrowserRouter>
    );
}