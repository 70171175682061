export let url:any = null;

var xhr = new XMLHttpRequest();

xhr.open('GET', '/config.json', false);

xhr.send(null);

if (xhr.status === 200) {

    let jsonResponse = JSON.parse(xhr.responseText);

    url = jsonResponse['APP_URL'];
}

export const headers = {
    
    headers: {
        'Authorization': '0',
        'Access-Control-Allow-Credentials': 'true',
    }
}

